import { where } from 'firebase/firestore';
import { FirestoreCollectionApi } from '../../db/FireStoreApi';
import { useEffect, useState } from 'react';

export type SiteDataProperty = {
  description: string;
  fieldType: string;
  hidden: boolean;
  label: string;
  name: string;
  objectType: string;
  options: SiteDataOption[];
};

export type SiteDataOption = {
  displayOrder: number;
  hidden: boolean;
  label: string;
  value: string;
};

export type CompanySiteProperties = {
  diversity_designation: SiteDataProperty;
  funding_round: SiteDataProperty;
  company_structure: SiteDataProperty;
  business_model: SiteDataProperty;
  arr_range: SiteDataProperty; //AnnualRecurringRevenues
  minority_designation: SiteDataProperty;
  pre_funding_valuation: SiteDataProperty;
  industry_type: SiteDataProperty; //InvestmentFirmTypes
  social_impact: SiteDataProperty;
  economic_impact: SiteDataProperty;
  environmental_impact: SiteDataProperty;
  diversity_and_inclusion: SiteDataProperty;
  use_of_funds: SiteDataProperty;
};

export type InvestorSiteProperties = {
  assets_under_management: SiteDataProperty; //InvestorAssetsRanges
  investor_type: SiteDataProperty;
  industry_type: SiteDataProperty; //InvestmentFirmTypes
  investment_round: SiteDataProperty;
  investment_size: SiteDataProperty;
  social_impact: SiteDataProperty;
  economic_impact: SiteDataProperty;
  environmental_impact: SiteDataProperty;
  diversity_and_inclusion: SiteDataProperty;
};

export type RealestateSiteProperties = {
  assets_under_management: SiteDataProperty; //InvestorAssetsRanges
  property_sector: SiteDataProperty;
  investment_type: SiteDataProperty;
  investment_strategy: SiteDataProperty;
  investment_structure: SiteDataProperty;
  return_structure: SiteDataProperty;
  anticipated_term: SiteDataProperty;
  tax_advantage: SiteDataProperty;
};

export type OrgTypeProperties = {
  anticipated_term: SiteDataProperty;
  arr_range: SiteDataProperty;
  assets_under_management: SiteDataProperty;
  business_model: SiteDataProperty;
  company_structure: SiteDataProperty;
  diversity_designation: SiteDataProperty;
  funding_round: SiteDataProperty;
  industry_type: SiteDataProperty;
  investment_strategy: SiteDataProperty;
  investment_structure: SiteDataProperty;
  investment_type: SiteDataProperty;
  investor_type: SiteDataProperty;
  minority_designation: SiteDataProperty;
  pre_funding_valuation: SiteDataProperty;
  property_sector: SiteDataProperty;
  return_structure: SiteDataProperty;
  tax_advantage: SiteDataProperty;
  investment_round: SiteDataProperty;
  investment_size: SiteDataProperty;
  social_impact: SiteDataProperty;
  economic_impact: SiteDataProperty;
  environmental_impact: SiteDataProperty;
  diversity_and_inclusion: SiteDataProperty;
  use_of_funds: SiteDataProperty;
};

const siteDataCollectionService = new FirestoreCollectionApi<SiteDataProperty>(
  'hubspotProperties',
);

export function getOrgTypeSiteProperties(): Promise<SiteDataProperty[]> {
  // Here Company means organisation(Company, Investor, RealestateOpportunity), will retrieve the properties of all types of organisations
  return siteDataCollectionService.getDocsByQuery([
    where('objectType', '==', 'companies'),
  ]);
}

export let companySiteProperties: CompanySiteProperties;
export let investorSiteProperties: InvestorSiteProperties;
export let realestateSiteProperties: RealestateSiteProperties;

export function useLoadSiteData() {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoading(true);
    getOrgTypeSiteProperties().then((data: SiteDataProperty[]) => {
      const orgTypeProperties: OrgTypeProperties = {} as OrgTypeProperties;
      data.forEach((property: SiteDataProperty) => {
        orgTypeProperties[property.name] = property;
      });

      //Load all types of Site Properties
      const {
        anticipated_term,
        arr_range,
        assets_under_management,
        business_model,
        company_structure,
        diversity_designation,
        funding_round,
        industry_type,
        investment_strategy,
        investment_structure,
        investment_type,
        investor_type,
        minority_designation,
        pre_funding_valuation,
        property_sector,
        return_structure,
        tax_advantage,
        investment_round,
        investment_size,
        social_impact,
        economic_impact,
        environmental_impact,
        diversity_and_inclusion,
        use_of_funds,
      } = orgTypeProperties;

      //Group in to OrgType Properties
      companySiteProperties = {
        diversity_designation,
        funding_round,
        company_structure,
        business_model,
        arr_range,
        minority_designation,
        pre_funding_valuation,
        industry_type,
        social_impact,
        economic_impact,
        environmental_impact,
        diversity_and_inclusion,
        use_of_funds,
      };
      investorSiteProperties = {
        assets_under_management,
        investor_type,
        industry_type,
        investment_round,
        investment_size,
        social_impact,
        economic_impact,
        environmental_impact,
        diversity_and_inclusion,
      };
      realestateSiteProperties = {
        assets_under_management,
        property_sector,
        investment_type,
        investment_strategy,
        investment_structure,
        return_structure,
        anticipated_term,
        tax_advantage,
      };

      setLoaded(true);
      setLoading(false);
    });
  }, []);

  return { loading, loaded };
}
