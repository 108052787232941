import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  addMsgToConversation,
  ConvoParticipant,
  onConvoMessageUpdate,
  updateConversationById,
  getConversationParticipants,
  upsertConvoParticipant,
  ConvoMessage,
  getOtherOrgIdByKeyProps,
} from '../../data/services/conversation/conversationService';

import { useCallback, useEffect, useState } from 'react';
import useProfileStore from '../../data/appState/profileStore';
import {
  ConnectionStatus,
  ConnectionType,
} from '../../domain/types/Connection';
import { getExploreDataByOrgId } from '../../data/services/explore/exploreService';
import { ExploreCardData } from '../../data/services/explore/ExploreCardData';
import { DEFAULT_IMG } from '../common/constants';
import { Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  displayDateFormatter,
  readableTime,
} from '../../data/services/explore/converters';
import { BackArrow, LucideSend } from '../common/utilComponents';
import useConversationsStore from '../../data/appState/conversationsStore';
import ColorCard from './ColorCard';
import { Modal } from 'antd';

export interface IChatWindowProps {
  conversationId: string;
}

export function ChatWindow({ conversationId }: IChatWindowProps) {
  const { profile, orgConnections, removeUnReadConvoId } = useProfileStore();

  //const [messages, setMessages] = useState<any>({});

  const { conversations, addConversationMsgs } = useConversationsStore();
  const [convoParticipants, setConvoParticipants] = useState<
    ConvoParticipant[]
  >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  // Check if firm is real estate and connection is pending - modal not visible
  const handleOpenMessagermodal = (connectionStatus, orgData) => {
    if (orgData?.orgType != null && connectionStatus !== 'PENDING') {
      setIsModalVisible(!isModalVisible);
    }
  };

  let messages = [];

  if (conversations && conversations[conversationId]) {
    messages = conversations[conversationId];
  }

  const [newMessage, setNewMessage] = useState<string>('');

  const navigateTo = useNavigate();

  const currentConversation = orgConnections?.find(
    (convo) => convo.db_ref_id === conversationId,
  );

  let otherOrgId =
    currentConversation?.toOrgId === profile?.organisationId
      ? currentConversation?.fromOrgId
      : currentConversation?.toOrgId;
  let otherOrgType =
    currentConversation?.toOrgId === profile?.organisationId
      ? currentConversation?.fromOrgType
      : currentConversation?.toOrgType;

  const isDirectConvo =
    currentConversation?.connectionType === ConnectionType.USER_USER;

  const connectionStatus: ConnectionStatus = isDirectConvo
    ? ConnectionStatus.ACCEPTED
    : currentConversation?.connectionStatus;

  const isPendingState = connectionStatus === ConnectionStatus.PENDING;

  const isActive = connectionStatus === ConnectionStatus.ACCEPTED;

  const [orgData, setOrgData] = useState<ExploreCardData | null>(null);

  const convoRawData = currentConversation as any;

  if ((!otherOrgId || !otherOrgType) && convoRawData?.keyProps && profile) {
    const resOrgId = getOtherOrgIdByKeyProps(convoRawData.keyProps, profile);

    if (resOrgId && convoRawData.keyProps[resOrgId]?.type === 'org') {
      otherOrgId = resOrgId;
      otherOrgType = convoRawData.keyProps[resOrgId].orgType;
    }
  }

  useEffect(() => {
    setOrgData(null);
    if (!otherOrgId || !otherOrgType) return;
    getExploreDataByOrgId(otherOrgId, otherOrgType).then(setOrgData);
  }, [otherOrgId, otherOrgType]);

  useEffect(() => {
    getConversationParticipants(conversationId).then(setConvoParticipants);
  }, [conversationId]);

  const updateParticipantToConversation = useCallback(() => {
    if (!profile || !conversationId) return;

    const { uid, displayName, photoURL, db_ref_id, title } = profile;
    if (!uid) return;
    const dateVal = new Date();

    const convoParticipant: ConvoParticipant = {
      profileId: db_ref_id,
      profileUid: uid,
      profileName: displayName || 'Participant',
      lastVisited: dateVal,
      updatedOn: dateVal,
      profilePic: photoURL ?? '',
      title: title,
    };

    upsertConvoParticipant(conversationId, convoParticipant);

    removeUnReadConvoId(conversationId);
  }, [conversationId, profile, removeUnReadConvoId]);

  useEffect(() => {
    updateParticipantToConversation();
  }, [profile, conversationId, updateParticipantToConversation]);

  useEffect(() => {
    if (currentConversation) {
      const { db_ref_id: convoId } = currentConversation;

      onConvoMessageUpdate(convoId, (msgs) => {
        addConversationMsgs(convoId, msgs);
      });
    }
  }, [currentConversation, addConversationMsgs]);

  const cancelConnectionRequest = () => {
    updateConversationById(currentConversation.db_ref_id, {
      updatedAt: new Date(),
      connectionStatus: ConnectionStatus.CANCELLED,
      CancelledBy: profile?.uid,
      isActive: false,
    });
  };

  const acceptConnectionRequest = async () => {
    await updateConversationById(currentConversation.db_ref_id, {
      connectionStatus: ConnectionStatus.ACCEPTED,
      isActive: true,
      updatedAt: new Date(),
      acceptedByUid: profile?.uid,
      acceptedByName: profile?.displayName,
    });

    await updateParticipantToConversation();
  };

  if (!conversationId) return <div> No conversation selected </div>;

  const SendNewMessage = async () => {
    setNewMessage('');
    if (!newMessage.trim() || !currentConversation || !profile?.uid) {
      return;
    }

    await addMsgToConversation(conversationId, {
      text: newMessage,
      createdById: profile?.uid,
      createdByName: profile?.displayName,
    });
    if (profile) {
      try {
        await upsertConvoParticipant(conversationId, {
          profileId: profile.id,
          profileUid: profile.uid,
          profileName: profile.displayName ?? '',
          profilePic: profile.photoURL,
          lastMessageSent: new Date(),
          updatedOn: new Date(),
          title: profile.title,
        });
      } catch (e) {
        console.error('Error updating participant', e);
      }
    }
  };

  const PendingActions = () => {
    const isRequestFromMyside =
      currentConversation?.fromOrgId === profile?.organisationId ||
      currentConversation?.createdByUid === profile?.uid;

    if (currentConversation?.connectionType === ConnectionType.ORG_ORG) {
      // if the connection is org to org
      if (!isRequestFromMyside) {
        return (
          <div className="chat_actions_btn">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Reject{' '}
            </button>
            <button
              className="btn btn_accept ant-btn-primary"
              onClick={acceptConnectionRequest}
            >
              {' '}
              Accept{' '}
            </button>
          </div>
        );
      } else {
        return (
          <div className="chat_actions_btn">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Cancel Request{' '}
            </button>
          </div>
        );
      }
    } else {
      // if the connection is user to user
      if (isRequestFromMyside) {
        return (
          <div className="chat_actions_btn ">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Cancel Request{' '}
            </button>
          </div>
        );
      } else {
        return (
          <div className="chat_actions_btn ">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Reject{' '}
            </button>
            <button
              className="btn btn_accept ant-btn-primary"
              onClick={acceptConnectionRequest}
            >
              {' '}
              Accept{' '}
            </button>
          </div>
        );
      }
    }
  };
  const getConvoTitleByKeyProps = (
    keyProps: any,
    connectionType: ConnectionType,
  ) => {
    // this is to support old data format
    if (!profile) return '';

    try {
      const { organisationId, uid } = profile;

      if (connectionType === ConnectionType.USER_USER) {
        for (const key in keyProps) {
          const value = keyProps[key];
          if (key !== uid && value.type === 'user') {
            return value.name;
          }
        }
      }

      if (connectionType === ConnectionType.ORG_ORG) {
        for (const key in keyProps) {
          const value = keyProps[key];
          if (key !== organisationId && value.type === 'org') {
            return value.name;
          }
        }
      }
    } catch (error) {
      return 'unknown';
    }
  };
  const PendingConvoMessage = () => {
    const isRequestByMe = currentConversation?.createdByUid === profile?.uid;

    if (currentConversation?.connectionType === ConnectionType.ORG_ORG) {
      const isRequestFromMyside =
        currentConversation?.fromOrgId === profile?.organisationId;

      const orgName = isRequestFromMyside
        ? currentConversation?.toOrgName
        : currentConversation?.fromOrgName;

      const createdByName = currentConversation?.createdByName
        ? currentConversation?.createdByName
        : 'Someone';

      const sentOn = currentConversation?.createdOn
        ? displayDateFormatter(
            currentConversation?.createdOn,
            'MMM DD, YYYY h:mm A',
          )
        : '';

      if (isRequestFromMyside)
        return isRequestByMe ? (
          <div className="messageContainer">
            You have Requested to {orgName} on {sentOn}
          </div>
        ) : (
          <div>
            {' '}
            A Request a has been to {orgName} on {sentOn}
          </div>
        );
      else
        return (
          <div>
            {' '}
            {createdByName} from {orgName} Requested a connection to your
            organization on {sentOn}
          </div>
        );
    } else {
      return isRequestByMe ? (
        <div>You have Requested </div>
      ) : (
        <div> You Have got Request </div>
      );
    }
  };

  let convoAvatar = (
    <Avatar
      shape="circle"
      size={45}
      src={<UserOutlined style={{ color: '#44475b' }} />}
    />
  );
  let convoTitle = '';
  let isGroupChat = false;
  let subTitle: string = [
    // 'You',
    ...convoParticipants
      .filter((c) => c.profileUid !== profile?.uid)
      .map((c) => c.profileName),
  ]
    .filter((t) => !!t)
    .join(', ');

  if (currentConversation) {
    convoTitle =
      profile?.organisationId === currentConversation?.toOrgId
        ? currentConversation.fromOrgName
        : currentConversation.toOrgName;
    isGroupChat = currentConversation.connectionType === ConnectionType.ORG_ORG;
  }

  let convoAvatarImgUrl: undefined | string;
  if (isGroupChat) {
    if (orgData) {
      convoAvatarImgUrl = orgData?.imageUrl;
      // if (orgData?.imageUrl)
      //   convoAvatar = (
      //     <Avatar shape="circle" size={45} src={orgData?.imageUrl} />
      //   );
      // else {
      //   convoAvatar = <ColorCard name={orgData?.title.charAt(0) || 'O'} />;
      // }
      convoTitle = orgData?.title;
      if (!convoTitle) {
        convoTitle =
          profile?.organisationId === currentConversation.toOrgId
            ? currentConversation.fromOrgName
            : currentConversation.toOrgName;
      }
    }
  } else {
    const convoParticpantId =
      currentConversation?.fromUserId === profile?.uid
        ? currentConversation.toUserId
        : currentConversation?.fromUserId;
    const convoParticipant = convoParticipants.find(
      (c) => c.profileUid === convoParticpantId,
    );

    if (convoParticipant?.profilePic)
      convoAvatarImgUrl = convoParticipant?.profilePic;
    // convoAvatar = (
    //   <Avatar shape="circle" size={45} src={convoParticipant.profilePic} />
    // )
    subTitle = convoTitle;
    if (currentConversation) {
      convoTitle =
        profile?.uid === currentConversation.fromUserId
          ? currentConversation.toUserName
          : currentConversation.fromUserName;
    }
  }

  if (!convoTitle && currentConversation?.keyProps) {
    convoTitle = getConvoTitleByKeyProps(
      currentConversation.keyProps,
      currentConversation.connectionType,
    );
  }

  if (convoAvatarImgUrl) {
    convoAvatar = <Avatar shape="circle" size={45} src={orgData?.imageUrl} />;
  } else {
    convoAvatar = <ColorCard name={convoTitle?.charAt(0) || 'O'} />;
  }

  const sortedMessages = [...messages]
    .filter((m: any) => {
      if (currentConversation?.connectionType === ConnectionType.USER_USER) {
        const createdBy = m.createdById;
        return currentConversation[createdBy] === true;
      }
      return true;
    })
    .sort((a: any, b: any) => {
      return b.createdOn.toDate().getTime() - a.createdOn.toDate().getTime();
    });

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      SendNewMessage();
    }
  };
  const MessagesList = (convoMessages: ConvoMessage[]) => {
    return convoMessages.map((message, index) => {
      const isMyMessage = message.createdById === profile?.uid;

      return (
        <div
          key={index}
          className={isMyMessage ? 'chat-msg right-msg' : 'chat-msg left-msg'}
        >
          <div className="chat-text">
            {' '}
            <span>{message.text} </span>{' '}
          </div>
          <div className="chat-status">
            <span>{!isMyMessage ? message?.createdByName : ''} </span>
            <span>• </span>
            <span>{readableTime(message?.createdOn)} </span>
          </div>
        </div>
      );
    });
  };

  const detailItemValue = (value: string | number | string[] | number[]) => {
    if (typeof value == 'object' && value.length) {
      return value.map((item: any, index) => (
        <span key={index}>{(index ? ', ' : '') + item}</span>
      ));
    } else {
      return value;
    }
  };

  const statusClass =
    currentConversation?.connectionType === ConnectionType.ORG_ORG
      ? currentConversation?.connectionStatus
      : '';

  return (
    <div className="chat_view">
      <div className="chat_header">
        <div className="back_icon">
          <BackArrow onClick={() => navigateTo(-1)} />
        </div>
        <div
          className="chat_user_content"
          onClick={() => handleOpenMessagermodal(connectionStatus, orgData)}
        >
          <div className="convo-avatar"> {convoAvatar} </div>
          <div className="chat_title sub_header_title">
            <h1>{convoTitle}</h1>

            <div className="chat_sub_title sub_header_actions"> {subTitle}</div>
          </div>
        </div>
      </div>
      <Modal open={isModalVisible} onCancel={handleCloseModal} footer={null}>
        <div className="message-details">
          <h2 className="popup_title">Message Details</h2>

          <div className="company-details">
            <h2>{orgData?.title}</h2>
            {orgData?.detailsList?.map((dlItem) => {
              if (dlItem?.label && dlItem?.value) {
                return (
                  <div className="list_group">
                    <div key={`id-${dlItem.label}`} className="text-group">
                      <label>{dlItem?.label}</label>
                      <span>
                        {dlItem?.value ? detailItemValue(dlItem?.value) : ''} {}
                      </span>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {orgData?.orgType?.toLowerCase() === 'realestate' ? (
            <></>
          ) : (
            <Link
              to={`/${orgData?.orgType?.toLowerCase()}/Messenger/${orgData?.id}`}
            >
              <p className="company-profile-link">
                View {orgData?.orgType?.toLowerCase()} Profile
              </p>
            </Link>
          )}
          <div className="members">
            <h4>Members</h4>
            <div className="members_list">
              {convoParticipants && convoParticipants?.length > 0 ? (
                convoParticipants.map((member, index: number) => {
                  return (
                    <div className="member" key={index}>
                      {member?.profilePic ? (
                        <Avatar
                          shape="circle"
                          size={45}
                          src={member?.profilePic}
                        />
                      ) : (
                        <div className="convo-avatar">
                          <ColorCard
                            name={member?.profileName?.charAt(0) || 'O'}
                          />
                        </div>
                      )}
                      <div className="member-info">
                        <span className="member-name">
                          {member?.profileName}
                        </span>
                        <span className="member-role">{member?.title}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="member-role">NO MEMBERS</div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <div className="request_div">
        {
          // SHOW PENDING Content or ACTIONS ONLY IF THE CONNECTION STATUS IS PENDING
          !isPendingState ? null : (
            <div className="request_section slim_scrollbar">
              <ConvoOrgDetails orgData={orgData}> </ConvoOrgDetails>
              <h3 className="pending_msg"> {PendingConvoMessage()}</h3>
              {PendingActions()}
            </div>
          )
        }

        <div className={'chat_window ' + statusClass}>
          <div className="chating_screen slim_scrollbar">
            {MessagesList(sortedMessages)}
          </div>

          {!isActive ? null : (
            <div className="chat-input-div">
              {/* <Iconsax/> */}
              <textarea
                className="text-input"
                placeholder="Type a message"
                value={newMessage}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setNewMessage(e.target.value);
                }}
              />
              <div className="lucide_send_icon">
                <LucideSend onClick={SendNewMessage} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function ConvoOrgDetails(props) {
  const { orgData } = props;

  if (!orgData) return <div> No Data </div>;

  const detailItemValue = (value: string | number | string[] | number[]) => {
    if (typeof value == 'object' && value.length) {
      return value.map((item: any, index) => (
        <span key={index}>{(index ? ', ' : '') + item}</span>
      ));
    } else {
      return value;
    }
  };

  return (
    <div
      key={orgData?.id}
      className={'request_user ' + orgData?.orgType}
      id={orgData?.id}
    >
      <div className="req_profile_card">
        <div className="card_profile">
          <img
            className="card_img"
            src={orgData?.imageUrl ? orgData.imageUrl : DEFAULT_IMG}
            alt="images"
          />
        </div>
        <div className="req_card">
          <div className="header_title">{orgData?.title} </div>
          <div
            className="entity-descriotion multine-ellipsis"
            dangerouslySetInnerHTML={{
              __html: orgData?.about,
            }}
          />
          <div className="table_list">
            {orgData?.detailsList?.map((dlItem) => {
              if (
                dlItem?.label &&
                dlItem?.value &&
                (!Array.isArray(dlItem?.value) ||
                  (Array.isArray(dlItem?.value) && dlItem?.value.length !== 0))
              ) {
                return (
                  <div key={`id-${dlItem.label}`} className="text-group">
                    <label>{dlItem?.label}</label>
                    <span>
                      {dlItem?.value ? detailItemValue(dlItem?.value) : ''} {}
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          {orgData?.orgType?.toLowerCase() === 'realestate' ? (
            <></>
          ) : (
            <div className="btn_secondary">
              <Link
                to={`/${orgData?.orgType?.toLowerCase()}/Messenger/${orgData?.id}`}
              >
                <Button type="default">View Profile</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
