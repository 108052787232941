import React from 'react';
import { uploadPdfToApi } from '../../data/services/company/companyService';
import { UploadCloudIcon } from '../common/utilComponents';
import useSiteStore from '../../data/appState/siteStore';

declare const window: any;
export interface IPdfToImagesUploaderProps {
  onUploadComplete: (result: any) => void;
  orgId?: string;
}

export function PdfToImagesUploader(props: IPdfToImagesUploaderProps) {
  const companyId = props.orgId ?? 'no-org'; // replace with the actual company ID

  const { setfullSiteLoader } = useSiteStore();
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debugger;
    if (event.target.files && event.target.files.length > 0) {
      const sFile = event.target.files[0];
      if (sFile) {
        setfullSiteLoader(true);
        setTimeout(() => {
          handleUpload(sFile);
        }, 200);
      }
    }
  };

  const handleUpload = async (selectedFile: File) => {
    try {
      let eniviroment: 'prod' | 'qa' = 'prod';

      const projectId = window?.FIREBASE_CONFIG?.projectId;
      if (
        projectId === 'wealthvp-webapp-qa' ||
        projectId === 'wealthvp-webapp-dev'
      ) {
        eniviroment = 'qa';
      }
      const result = await uploadPdfToApi(selectedFile, companyId, eniviroment);
      //await uploadPdfToApi(selectedFile, companyId, 'qa');
      if (result) {
        props.onUploadComplete({ ...result, size: selectedFile.size });
      }
    } catch (error) {
    } finally {
      setfullSiteLoader(false);
    }
  };

  return (
    <div>
      <div className="upload-container">
        <label className="upload-box">
          <div className="upload-icon">
            {' '}
            <UploadCloudIcon />
          </div>

          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
          />
          <div>
            <p className="file-size-info">PDF format (max. 10MB)</p>
          </div>
        </label>
      </div>
    </div>
  );
}
