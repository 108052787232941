import * as React from 'react';
import useProfileStore from '../../data/appState/profileStore';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Input, Modal } from 'antd';
import { getExploreData } from '../../data/services/explore/exploreService';
import { ExploreCardData } from '../../data/services/explore/ExploreCardData';
import ExploreCard from '../components/ExploreCard';
import { useEffect, useState, useRef } from 'react';
import { ProcessState } from '../common/types';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import { Company } from '../../domain/types/Company';
import {
  ConnectionStatus,
  ConnectionType,
  ConversationType,
} from '../../domain/types/Connection';
import { RealEstateOpportunity } from '../../domain/types/RealEstateOpportunity';
import { ExpandedREOpportunity } from '../components/ExpandedREOppurtunityVIew';
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView';
import { InvestmentFirm } from '../../domain/types/Investor';
import {
  ConvoMessage,
  ConvoParticipant,
  createConversation,
  getConvoByKey,
} from '../../data/services/conversation/conversationService';
import useExploreStore from '../../data/appState/exploreStore';
import { BackArrow, CloseIcon, FilterIcon } from '../common/utilComponents';
import toast from '../common/Toast';
import { ExploreTypeSwitch } from '../components/ExploreTypeSwitch';
import { OrgType } from '../../domain/types/Profile';
import ExploreFiltersView from '../components/ExploreFiltersView';
import { CompanyCard } from '../components/CompanyCard/CompanyCard';
import { getEndOfWeek, getStartOfWeek } from '../utils/formatters';
import { CompanyPageContent } from './Company/CompanyPageContents';
import { isMobile } from '../common/utils';

type ConnectionsState = {
  [orgId: string]: ConnectionStatus;
};

function defaultExploreType(orgType: string | undefined): string {
  orgType = orgType?.toLowerCase();
  if (orgType === 'company') {
    return 'investors';
  }
  if (orgType === 'realestate') {
    return 'investors';
  }
  if (orgType === 'investor') {
    return 'companies';
  }
  return 'companies';
}

function browseTitle(exploreType: string | undefined): JSX.Element {
  exploreType = exploreType?.toLowerCase();
  if (exploreType === 'companies') {
    return <div className="exp_title">Explore Companies</div>;
  }
  if (exploreType === 'realestates') {
    return <div className="exp_title">Explore Real Estate</div>;
  }
  if (exploreType === 'investors') {
    return <div className="exp_title">Explore Investors</div>;
  }
  return <div className="exp_title">Explore</div>;
}

export default function Explore() {
  const navigateTo = useNavigate();

  const { profile, orgConnections } = useProfileStore();
  const [listScrollYPostion, setlistScrollYPostion] = useState(0);

  const { searchtype, orgId: paramOrgId, orgName: paramOrgName } = useParams();
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [searchKey, setSearchKey] = useState<string>('');
  const debounceTimeout = useRef<any>(null);

  const handleKeyDown = (e) => {
    const value = e.currentTarget.value;

    // Clear the previous timer
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timer
    debounceTimeout.current = setTimeout(() => {
      setSearchKey(value);
    }, 300); // 300 ms delay
  };

  const maxRequestsPerWeek = 10;
  // Explore Type ...
  const exploreType: string = searchtype
    ? searchtype
    : defaultExploreType(profile?.orgType);

  const [exploreData, setExploreData] = React.useState<ExploreCardData[]>([]);
  const [orgConnectionStates, setOrgConnectionStates] =
    React.useState<ConnectionsState>({});
  const [selectedCard, setSelectedCard] =
    React.useState<ExploreCardData | null>(null);
  const [requestingCard, setRequestingCard] =
    React.useState<ExploreCardData | null>(null);
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.Idle,
  );

  const today = new Date();
  const startOfWeek = getStartOfWeek(today);
  const endOfWeek = getEndOfWeek(startOfWeek);

  const [requestMessage, setRequestMessage] = useState<string>('');

  const { selectedOptions, clearSelectedOptions } = useExploreStore();
  const [filteredExploreData, setFilteredExploreData] = React.useState<
    ExploreCardData[]
  >([]);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const closeModal = () => {
    setRequestMessage('');
    setRequestingCard(null);
  };

  const loadExploreData = (expType) => {
    setProcessState(ProcessState.Loading);
    getExploreData(expType)
      .then((data) => {
        setExploreData(data);

        setProcessState(ProcessState.Success);
      })
      .catch((e) => {
        setProcessState(ProcessState.Failed);
      });
  };

  useEffect(() => {
    if (exploreData.length && (selectedOptions.length || searchKey)) {
      let filteredData = exploreData;

      if (selectedOptions.length) {
        filteredData = exploreData.filter((cardData) => {
          //TODO: Refactor this to a Filter function
          // TODO: Refactor this to support AND Condition

          const { orgData } = cardData;
          const orgDataValues = Object.values(orgData);
          let isSelected = false;
          for (const value of orgDataValues) {
            if (typeof value === 'string' || typeof value === 'number') {
              const index = selectedOptions.findIndex(
                (option) => option.value === value,
              );
              isSelected = index > -1;
            }

            // Check if value is string array
            if (Array.isArray(value) && value.length > 0) {
              const index = value.findIndex(
                (v) =>
                  selectedOptions.findIndex((option) => option.value === v) >
                  -1,
              );
              isSelected = index > -1;
            }

            if (isSelected) break;
          }
          return isSelected;
        });
      }

      if (searchKey) {
        filteredData = filteredData.filter((cardData) => {
          const { title } = cardData;
          const isMatch = title.toLowerCase().includes(searchKey.toLowerCase());
          return isMatch;
        });
      }

      setFilteredExploreData(filteredData);
    } else {
      setFilteredExploreData(exploreData);
    }
  }, [selectedOptions, exploreData, searchKey]);

  useEffect(() => {
    if (orgConnections?.length) {
      const initState = {} as ConnectionsState;
      const orgConnectionsState = orgConnections
        .filter((oc) => oc.connectionType === 'ORG_ORG')
        .reduce((acc, conn) => {
          const otherOrgId =
            conn.fromOrgId === profile?.organisationId
              ? conn.toOrgId
              : conn.fromOrgId;

          acc[otherOrgId] = conn.connectionStatus;
          return acc;
        }, initState);

      setOrgConnectionStates(orgConnectionsState);
    }
  }, [orgConnections, profile?.organisationId]);

  useEffect(() => {
    loadExploreData(exploreType);
  }, [exploreType]);

  useEffect(() => {
    if (paramOrgId && paramOrgName) {
      const cardData = exploreData.find((card) => card.id === paramOrgId);
      if (cardData) {
        setSelectedCard(cardData);
      } else {
        setSelectedCard(null);
      }
    } else {
      setSelectedCard(null);
    }
  }, [paramOrgId, paramOrgName, exploreData]);

  const handleConnect = async (cardData: ExploreCardData) => {
    setIsRequesting(true);
    if (!profile) return;

    try {
      const {
        displayName: createdByName,
        uid: createdByUid,
        orgType: fromOrgType,
        organisationId: fromOrgId,
        organisationName: fromOrgName,
      } = profile;

      const myConnections = orgConnections.filter((o) => {
        let createdOn = o.createdAt ?? o.createdOn;

        if (createdOn?.toDate) {
          createdOn = createdOn.toDate();
        }
        return (
          o.createdByUid === createdByUid &&
          createdOn > startOfWeek &&
          createdOn <= endOfWeek
        );
      });

      const maxRequestsReached: boolean =
        myConnections?.length >= maxRequestsPerWeek;

      if (maxRequestsReached) {
        toast.error(
          'Weekly Limit Reached',
          `Our platform allows only ${maxRequestsPerWeek} requests per week`,
        );
        setRequestingCard(null);
        return;
      }

      // let id = profile.organisationId + "_" + cardData.id;

      let { id: toOrgId, orgType: toOrgType, title: toOrgName } = cardData;

      if (toOrgType === 'REALESTATE') {
        let orgData: RealEstateOpportunity =
          cardData.orgData as RealEstateOpportunity;
        toOrgId = orgData?.sponserId ?? '';
        toOrgName = cardData.orgData?.name ?? '';
      }

      const initState = {
        createdByUid,
        createdByName,
        fromOrgId,
        fromOrgType,
        fromOrgName,
        toOrgId,
        toOrgType,
        toOrgName,
        connectionType: ConnectionType.ORG_ORG,
        targetId: cardData.id,
        type: ConversationType.GROUP,
        [toOrgId]: true,
        [fromOrgId!]: true,
      };

      const convoMessage: ConvoMessage = {
        text: requestMessage,
        createdById: profile.uid!,
      };

      const convoParticipant: ConvoParticipant = {
        profileId: profile.id!,
        profileUid: profile.uid!,
        profileName: profile.displayName!,
        profilePic: profile.photoURL ?? '',
      };

      const connectionKey = [fromOrgId, toOrgId].sort().join('_');

      await createConversation(
        initState,
        convoMessage,
        convoParticipant,
        connectionKey,
      );
      setRequestingCard(null);
      //vmc
      setRequestMessage('');
    } catch (error) {
    } finally {
      setIsRequesting(false);
    }
  };

  const gotoConversation = (cardData: ExploreCardData) => {
    let orgId = cardData.id;
    if (cardData.orgType === 'REALESTATE') {
      let orgData: RealEstateOpportunity =
        cardData.orgData as RealEstateOpportunity;
      if (orgData?.sponserId) orgId = orgData?.sponserId;
    }

    const connectionKey = [orgId, profile?.organisationId].sort().join('_');

    getConvoByKey(connectionKey, ConnectionType.ORG_ORG).then((convos) => {
      if (convos.length > 0) {
        navigateTo(`/chat/${convos[0].db_ref_id}`);
      }
    });
  };

  const cardStatusAction = (cardData?: ExploreCardData | null): JSX.Element => {
    if (!cardData) return <></>;

    let orgId = cardData.id;
    if (cardData.orgType === 'REALESTATE') {
      let orgData: RealEstateOpportunity =
        cardData.orgData as RealEstateOpportunity;
      if (orgData?.sponserId) orgId = orgData?.sponserId;
    }

    const cardStatus = orgConnectionStates[orgId];

    const isNotConnected =
      !cardStatus || cardStatus === ConnectionStatus.NOT_CONNECTED;

    if (isNotConnected) {
      return (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setRequestingCard(cardData);
          }}
        >
          Message
        </Button>
      );
    } else {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return (
        <div
          className="card_status"
          onClick={(e) => {
            e.stopPropagation();
            gotoConversation(cardData);
          }}
        >
          <span>{cardStatus}</span>
        </div>
      );
    }
  };

  const setExpandedCardView = (cardData) => {
    const route = isAdmin
      ? `/admin/explore/${exploreType}/${cardData.id}/${encodeURIComponent(cardData.title)}`
      : `/explore/${exploreType}/${cardData.id}/${encodeURIComponent(cardData.title)}`;
    navigateTo(route);
  };

  const ProcessingListView = () => {
    switch (processState) {
      case ProcessState.Loading:
        return <ShimmerSimpleGallery card imageHeight={300} caption />;
      case ProcessState.Failed:
        return <h1>Failed to load data</h1>;
      case ProcessState.Success:
        return (
          <div className="layout-grid">
            {filteredExploreData.length > 0 ? (
              filteredExploreData.map((cardData) => {
                const connectionStatus = orgConnectionStates[cardData.id];
                return cardData.orgType === 'INVESTOR' ||
                  cardData.orgType === 'REALESTATE' ? (
                  <ExploreCard
                    key={cardData.id}
                    cardData={cardData}
                    cardStatusAction={() => cardStatusAction(cardData)}
                    onCardSelect={(cardData) => {
                      setlistScrollYPostion(window.scrollY);
                      window.scrollTo(0, 0);
                      setSelectedCard(cardData);
                      setExpandedCardView(cardData);
                    }}
                  />
                ) : (
                  <CompanyCard
                    cardData={cardData}
                    connectionStatus={connectionStatus}
                    sendRequestToConnect={() => setRequestingCard(cardData)}
                    gotoConversation={() => gotoConversation(cardData)}
                    onCardSelect={(cardData) => {
                      setlistScrollYPostion(window.scrollY);
                      window.scrollTo(0, 0);
                      setSelectedCard(cardData);
                      setExpandedCardView(cardData);
                    }}
                  />
                );
              })
            ) : (
              <h1> No Data Found </h1>
            )}
          </div>
        );
      default:
        return <h1>Loading.. </h1>;
    }
  };

  const ExpandedCardView = (): JSX.Element => {
    let resView = <></>;

    switch (exploreType) {
      case 'companies':
        const company = selectedCard?.orgData as Company;
        resView = <CompanyPageContent company={company} />;
        break;

      case 'realestates':
        const re_opp = selectedCard?.orgData as RealEstateOpportunity;
        resView = (
          <ExpandedREOpportunity
            opportunity={re_opp}
            cardStatusAction={() => cardStatusAction(selectedCard)}
          />
        );
        break;

      case 'investors':
        const invFirm = selectedCard?.orgData as InvestmentFirm;
        resView = (
          <ExpandedInvestmentFirmView
            investmentFirm={invFirm}
            cardStatusAction={() => cardStatusAction(selectedCard)}
          ></ExpandedInvestmentFirmView>
        );
        break;

      default:
        resView = <></>;
        break;
    }

    return resView;
  };

  const listWrapClass =
    selectedCard != null ? 'list-wrap single-column' : 'list-wrap';

  const profileOrgType = profile?.orgType as OrgType;
  const isAdmin = profileOrgType === 'ADMIN';
  const connectionStatus = selectedCard && orgConnectionStates[selectedCard.id];
  const isNotConnected =
    !connectionStatus || connectionStatus === ConnectionStatus.NOT_CONNECTED;
  return (
    <div className="body_layout">
      {!selectedCard ? (
        <>
          <div className="explore_header">
            <div className="filter-wrap">
              <div className="switch-wrap">{browseTitle(exploreType)}</div>

              <div className="search_div">
                <Input
                  className="search-input-field"
                  placeholder={`Search ${exploreType === 'realestates' ? 'Real Estate' : exploreType}`}
                  type="text"
                  name=""
                  id=""
                  onChange={handleKeyDown}
                />
              </div>

              <div className="">
                <div
                  className="filter_icon"
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}
                >
                  {selectedOptions.length !== 0 ? (
                    <div className="filter_count">
                      {selectedOptions?.length}
                    </div>
                  ) : (
                    <></>
                  )}

                  <FilterIcon />
                </div>

                <div
                  className={`filter-panel ${showFilters ? 'show' : 'hide'}`}
                >
                  <div className="filter_panel_flex">
                    <div className="filter_header">
                      <h3>Filters</h3>
                      <CloseIcon
                        onClick={() => {
                          setShowFilters(false);
                        }}
                      />
                    </div>
                    {profile?.orgType === 'INVESTOR' || isAdmin ? (
                      <ExploreTypeSwitch
                        exploreType={exploreType}
                        orgType={profile?.orgType}
                        onSwitch={(exploreType: string) => {
                          setSelectedCard(null);
                          const exploreroute = isAdmin
                            ? '/admin/explore/'
                            : '/explore/';
                          const route = exploreroute + exploreType;
                          clearSelectedOptions();
                          navigateTo(route);
                        }}
                      ></ExploreTypeSwitch>
                    ) : (
                      <></>
                    )}

                    {exploreType && exploreData?.length ? (
                      <ExploreFiltersView
                        eploreCards={exploreData}
                        exploreType={exploreType}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="filter_buttons">
                      <Button
                        type="text"
                        className="reset-btn"
                        onClick={() => {
                          clearSelectedOptions();
                        }}
                      >
                        Reset
                      </Button>

                      <Button
                        type="primary"
                        className="save-btn"
                        onClick={() => {
                          setShowFilters(false);
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="card_container">
        <div className={listWrapClass}>
          {
            /* Results Cards List */
            ProcessingListView()
          }

          {
            /* Selected Card */

            !selectedCard ? (
              <></>
            ) : (
              <div className="relative">
                <div className="sticky top-[80px] z-20 flex justify-between items-center px-4 py-2 shadow-md rounded-md bg-cool-black">
                  <div className="flex items-center gap-2">
                    <BackArrow
                      onClick={() => {
                        setTimeout(() => {
                          if (listScrollYPostion) {
                            window.scrollTo(0, listScrollYPostion + 50);
                          }
                        }, 500);
                        setSelectedCard(null);
                        const route = isAdmin
                          ? `/admin/explore/${exploreType}`
                          : `/explore/${exploreType}`;
                        navigateTo(route);
                      }}
                    />
                    <h2>Back</h2>
                  </div>

                  {/* Right Section: Message Button or Connection Status */}
                  <div>
                    {isNotConnected ? (
                      <Button
                        className="py-2 px-4 font-medium text-base leading-7 text-black bg-yellow-400 hover:bg-yellow-500"
                        onClick={() => setRequestingCard(selectedCard)}
                        type="primary"
                      >
                        Message
                      </Button>
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => gotoConversation(selectedCard)}
                      >
                        <span className="text-white">{connectionStatus}</span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Scrollable Content */}
                <div className="slide-view overflow-y-auto mt-4">
                  {ExpandedCardView()}
                  {isMobile && (
                    <div className="flex items-center justify-center">
                      <div className="mt-8 flex-col justify-center items-center text-center w-80">
                        {isNotConnected ? (
                          <>
                            <p className="font-semibold text-lg">
                              Send request?
                            </p>
                            <p className="text-xs font-normal text-gray">
                              A connection request will be sent with your
                              initial message so you can start communicating
                              with each other.
                            </p>
                            <Button
                              className="mt-4 py-2 px-4 font-medium text-base leading-7 text-black bg-yellow-400 hover:bg-yellow-500"
                              onClick={() => setRequestingCard(selectedCard)}
                              type="primary"
                            >
                              Message
                            </Button>
                          </>
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={() => gotoConversation(selectedCard)}
                          >
                            <span className="text-white">
                              {connectionStatus}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          }
        </div>
      </div>
      {/* request confirmation modal */}

      <div>
        <Modal
          className="popup_message"
          open={requestingCard != null}
          title={'Request To Connect With ' + requestingCard?.title}
          onOk={closeModal}
          onCancel={closeModal}
          footer={[
            <Button
              key="back"
              style={{ width: '100%' }}
              type="default"
              onClick={closeModal}
            >
              Cancel
            </Button>,
            <Button
              style={{ width: '100%' }}
              key="submit"
              type="primary"
              loading={isRequesting}
              onClick={() => {
                handleConnect(requestingCard!);
              }}
            >
              Send
            </Button>,
          ]}
        >
          <p className="paragraph">
            To connect with this company, send them a message request. <br />{' '}
            They will need to accept your request to start a conversation.
          </p>

          <div className="request_text_box">
            <label htmlFor=""> Message</label>
            <textarea
              name=""
              id=""
              placeholder="Enter a message..."
              //VMC
              value={requestMessage}
              onChange={(e) => {
                setRequestMessage(e.target.value);
              }}
            ></textarea>
          </div>
        </Modal>
      </div>
    </div>
  );
}
