import React from 'react';
import { Company } from '../../../domain/types/Company';
import Thumbnail from '../../components/Thumbnail/Thumbnail';

interface ProductOverviewSectionProps {
  company: Company;
}

const ProductOverviewSection: React.FC<ProductOverviewSectionProps> = ({
  company,
}: ProductOverviewSectionProps) => {
  const companyIntro = company.companyFiles
    ? company.companyFiles?.find((cf) => cf.category === 'companyIntro')
    : null;

  return (
    <div className="mb-16">
      <p className="font-semibold text-xl text-celadon-green mb-4">
        Product Overview
      </p>
      {companyIntro ? (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="md:col-span-2">
            <Thumbnail videoUrl={companyIntro.url} title={company.name} />
          </div>
          <div className="text-white ml-0 md:ml-8 mt-4 md:mt-0">
            <p className="font-semibold text-lg mb-2">Value Proposition</p>
            <div
              dangerouslySetInnerHTML={{
                __html: company?.about || '',
              }}
            />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-5 gap-x-8 gap-y-4">
          {/* Left Div - Stacks on mobile, 1/4 width on medium+ */}
          <div className="md:col-span-2">
            <Thumbnail imageUrl={company.logoUri} title={company.name} />
          </div>
          {/* Right Div - Stacks on mobile, 3/4 width on medium+ */}
          <div className="md:col-span-3 text-white">
            <p className="font-semibold text-lg mb-2">Value Proposition</p>
            <div
              dangerouslySetInnerHTML={{
                __html: company?.about || '',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductOverviewSection;
