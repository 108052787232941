import React from 'react';
import { Tag } from './Tag';

interface TagsListProps {
  value?: null | string | number | string[] | number[];
  maxCount?: number;
}
export const TagsList: React.FC<TagsListProps> = ({ value, maxCount = 3 }) => {
  if (!value) return null;
  const extraCount = Array.isArray(value) ? value.length - maxCount : 0;
  const renderCount =
    Array.isArray(value) && value.length && value.length > maxCount;
  if (typeof value === 'object' && value.length) {
    return (
      <div className="flex flex-wrap items-center gap-2 w-full relative">
        {value.map((item: any, index) => {
          if (index > 2) return null;
          return <Tag label={item} index={index} />;
        })}
        {renderCount ? (
          <div className="text-wrapper-2">+{extraCount} </div>
        ) : null}
      </div>
    );
  } else {
    return <>{value}</>;
  }
};
