import React, { useRef, useState } from 'react';

interface VideoThumbnailProps {
  videoUrl: string;
  videoLink?: string;
}

export const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  videoUrl,
  videoLink,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  return videoLink ? (
    <iframe
      title="Video"
      src={videoLink}
      allowFullScreen
      className="object-cover w-full h-full rounded-t-lg"
    />
  ) : (
    <div className="relative w-full mx-auto object-cover h-full cursor-pointer">
      <video
        ref={videoRef}
        src={videoUrl}
        className="object-cover w-full h-full rounded-b-none rounded-t-lg"
        onClick={handlePlayPause}
      />
      <button
        onClick={handlePlayPause}
        className={`absolute inset-0 m-auto flex items-center justify-center bg-black bg-opacity-50 text-white rounded-full w-16 h-16 ${
          isPlaying ? 'opacity-0 hover:opacity-100' : 'opacity-100'
        } transition-opacity duration-200`}
      >
        {isPlaying ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8"
            fill="white"
            viewBox="0 0 24 24"
          >
            <rect x="7" y="6" width="4" height="12" />
            <rect x="13" y="6" width="4" height="12" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8"
            fill="white"
            viewBox="0 0 24 24"
          >
            <path d="M8 5v14l11-7z" />
          </svg>
        )}
      </button>
    </div>
  );
};
