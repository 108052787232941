import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { getStorageImageUrls } from '../../../data/db/FireStoreApi';
import { Company, CompanyFileCategories } from '../../../domain/types/Company';
import { CompanyMetaData } from '../../components/CompanyMetaData/CompanyMetaData';
import CompanyDetailsSection from './CompanyDetailsSection';
import ProductOverviewSection from './ProductOverviewSection';
import { TeamOverviewSection } from './TeamOverviewSection';
import { convertNumbertoCurrency } from '../../common/utils';
import { formatAllCapsSnakeToReadableStructure } from '../../common/formatter';

interface CompanyPageContentProps {
  company: Company;
}
export const CompanyPageContent: React.FC<CompanyPageContentProps> = ({
  company,
}) => {
  const [pitchDeckImageFiles, setPitchDeckImageFiles] = useState<string[]>([]);
  const companyFiles = company?.companyFiles ? company?.companyFiles : [];
  const pitchDeckUrls: string[] = companyFiles
    .filter((f) => f.category === CompanyFileCategories.pitchDeck && f.url)
    .map((file) => file.url!);

  useEffect(() => {
    if (company?.pitchDeckImageFiles?.length) {
      getStorageImageUrls(company?.pitchDeckImageFiles).then((data) => {
        setPitchDeckImageFiles(data);
      });
    }
  }, [company?.pitchDeckImageFiles]);

  const offeringType = company?.structures
    ?.map((structure) => formatAllCapsSnakeToReadableStructure(structure))
    .join(', ');

  return (
    <div className="re-company-deck">
      <CompanyMetaData
        socialLinks={company?.socialLinks}
        logoUrl={company?.logoUri}
        name={company?.name}
        fundingRound={company?.fundingRound}
        offeringType={offeringType}
        seekingAmount={`${convertNumbertoCurrency(company?.minimumInvestment)} - ${convertNumbertoCurrency(company?.maximumInvestment)}`}
      />
      <CompanyDetailsSection
        company={company}
        pitchDeckImageFiles={pitchDeckImageFiles}
        pitchDeckUrls={pitchDeckUrls ?? []}
      />
      <Divider />
      <ProductOverviewSection company={company} />
      <Divider />
      <TeamOverviewSection teamMembers={company.teamMembers} />
    </div>
  );
};
