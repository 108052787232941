import React, { useState } from 'react';
import { ImageViewer } from '../../components/ImageViewer';
import { Company } from '../../../domain/types/Company';
import { DocsViewer } from '../../common/DocsViewer';
import TagContainer from '../../components/Tag/TagContainer';
import { convertNumbertoCurrency, isMobile } from '../../common/utils';
import {
  formatAllCapsSnakeToReadableStructure,
  formatRevenueRange,
} from '../../common/formatter';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

interface CompanyDetailsSectionProps {
  company: Company;
  pitchDeckImageFiles: string[];
  pitchDeckUrls: string[];
}

const CompanyDetailsSection = ({
  company,
  pitchDeckImageFiles,
  pitchDeckUrls,
}: CompanyDetailsSectionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100); // Initialize zoomLevel as 100%

  const handleFullscreen = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const zoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 25, 200)); // Cap zoom at 200%
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 25, 100)); // Cap zoom out at 100%
  };

  const useOfFunds = company?.useOfFunds
    ?.map((structure) => formatAllCapsSnakeToReadableStructure(structure))
    .join(', ');

  const hasImpactAreas =
    company?.socialImpact?.length ||
    company?.economicImpact?.length ||
    company?.diversityandInclusion?.length;
  return (
    <div className="p-4 md:p-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="preview-docs-view col-span-2">
          {company?.pitchDeckImageFiles?.length ? (
            <ImageViewer
              imageUrls={pitchDeckImageFiles}
              onFullscreen={handleFullscreen}
            />
          ) : (
            <DocsViewer filesUrls={pitchDeckUrls} />
          )}
        </div>
        <div className="text-white">
          <div className="mb-8">
            <p className="font-semibold text-lg mb-2">Company Details</p>
            <p className="mb-1">
              <span className="font-normal text-base text-gray">
                Founded:&nbsp;
              </span>
              {company.founded}
            </p>
            <p className="mb-1">
              <span className="font-normal text-base text-gray">
                Business Model:&nbsp;
              </span>{' '}
              {company.businessModal}
            </p>
            <p className="mb-1">
              <span className="font-normal text-base text-gray">
                Employee Count:&nbsp;
              </span>{' '}
              {company.employeeCount}{' '}
            </p>
            <p className="mb-1">
              <span className="font-normal text-base text-gray">
                Location:&nbsp;
              </span>{' '}
              {company.city}
            </p>
            <div className="flex">
              <span className="font-normal text-base text-gray">
                Website: &nbsp;
              </span>
              <a
                href={company.websiteUri}
                target="_blank"
                rel="noopener noreferrer"
                className="text-yellow-green"
              >
                {company.websiteUri}
              </a>
            </div>
          </div>
          <div className="mb-8">
            <TagContainer
              titleFontSize={'text-lg'}
              tagListTitle={'Industry Focus'}
              tags={company?.industries?.map((industry) =>
                industry
                  .replace(/_/g, ' ')
                  .toLowerCase()
                  .replace(/\b\w/g, (char) => char.toUpperCase()),
              )}
            />
          </div>
          <div>
            <p className="font-semibold text-lg mb-2">Financial Overview</p>
            <div className="flex flex-col">
              <p className="mb-1 font-normal text-base text-gray">
                Valuation:&nbsp;
                <span className="text-white">
                  {company.valuation
                    ? convertNumbertoCurrency(company.valuation)
                    : 'N/A'}
                </span>
              </p>
              <p className="mb-1 font-normal text-base text-gray">
                Raised to Date:&nbsp;
                <span className="text-white">
                  {convertNumbertoCurrency(company.fundingRaisedToDate)}
                </span>
              </p>
              <p className="mb-1 font-normal text-base text-gray">
                Annual Revenue:&nbsp;
                <span className="text-white">
                  {formatRevenueRange(company.annualRecurringRevenue || '')}
                </span>
              </p>
              <p className="mb-1 font-normal text-base text-gray">
                Use of Funds:&nbsp;
                <span className="text-white">{useOfFunds}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {hasImpactAreas && (
        <p className="font-semibold text-lg mt-8 mb-4">
          Impact Areas of Interest
        </p>
      )}
      <div className="space-y-4">
        {company?.socialImpact?.length && (
          <TagContainer
            titleFontSize={'text-lg'}
            tagListTitle={'Social Impact:'}
            tags={company?.socialImpact?.map((industry) =>
              industry
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()),
            )}
            titleBottomMargin="mb-2"
            titleHorizontal={!isMobile}
          />
        )}
        {company?.economicImpact?.length && (
          <TagContainer
            titleFontSize={'text-lg'}
            tagListTitle={'Environmental Impact:'}
            tags={company?.environmentImpact?.map((industry) =>
              industry
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()),
            )}
            titleBottomMargin="mb-2"
            titleHorizontal={!isMobile}
          />
        )}
        {company?.economicImpact?.length && (
          <TagContainer
            titleFontSize={'text-lg'}
            tagListTitle={'Economic Impact:'}
            tags={company?.economicImpact?.map((industry) =>
              industry
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()),
            )}
            titleBottomMargin="mb-2"
            titleHorizontal={!isMobile}
          />
        )}
        {company?.diversityandInclusion?.length && (
          <TagContainer
            titleFontSize={'text-lg'}
            tagListTitle={'Diversity and Inclusion:'}
            tags={company?.diversityandInclusion?.map((industry) =>
              industry
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()),
            )}
            titleBottomMargin="mb-2"
            titleHorizontal={!isMobile}
          />
        )}
      </div>
      <Modal
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        className="full_view_screen"
      >
        <div className={`zoom-${zoomLevel} pitchdeck-fullview slim_scrollbar`}>
          {pitchDeckImageFiles.map((url, index) => (
            <img
              className="pitchdeck-fullview-img"
              key={index}
              src={url}
              alt="pitch deck"
              style={{ width: '100%', height: 'auto' }}
            />
          ))}
        </div>

        <div className="control_overlay">
          <div className="popup_zoom_contral">
            <ZoomInOutlined onClick={zoomIn} />
            <ZoomOutOutlined onClick={zoomOut} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompanyDetailsSection;
