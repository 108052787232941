import React from 'react';
import { colors } from '../../common/colors';
import SocialIcons from '../SocialIconsView';

export interface TeamMemberType {
  displayName?: string;
  photoUrl?: string;
  socialLink?: string;
  title?: string;
  description?: string;
}

interface TeamMemberProps {
  teamMember: TeamMemberType;
}

export const TeamMember: React.FC<TeamMemberProps> = ({ teamMember }) => {
  return (
    <div className="flex flex-col items-center text-center">
      {teamMember.photoUrl ? (
        <img
          src={teamMember.photoUrl}
          alt={teamMember.displayName}
          className="rounded-full w-24 h-24 object-cover"
        />
      ) : (
        <div
          className={`rounded-full w-24 h-24 ${
            colors[Math.floor(Math.random() * colors.length)]
          }`}
        />
      )}
      <p className="mt-2 font-semibold text-lg">{teamMember.displayName}</p>
      {teamMember.title ? (
        <p className="text-gray text-base">{teamMember.title}</p>
      ) : (
        <div className="h-6" />
      )}
      <p className="text-base text-white mb-1">{teamMember.description}</p>
      <SocialIcons iconsList={[teamMember?.socialLink]} />
    </div>
  );
};
