import React, { useEffect, useState } from 'react';
import { colors } from '../common/colors';

interface ColorCardProps {
  name: string;
  circle?: boolean;
}

let colorIndex = 0; // To keep track of the current index

const ColorCard: React.FC<ColorCardProps> = ({ name, circle }) => {
  const [colorClass, setColorClass] = useState<string>(colors[colorIndex]);

  useEffect(() => {
    // Update the background color sequentially
    setColorClass(colors[colorIndex]);
    colorIndex = (colorIndex + 1) % colors.length; // Move to the next color
  }, []);

  return (
    <div
      className={`color_card flex items-center justify-center ${
        circle ? 'w-32 h-32 rounded-full' : 'w-full h-full rounded-t-lg'
      } ${colorClass}`}
    >
      <div className="d-first_letter">{name}</div>
    </div>
  );
};

export default ColorCard;
